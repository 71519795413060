import React from 'react'
import { RegisterFooter, RegisterHeader } from '../../Components/Index'

const Guidline = () => {
  return (
    <React.Fragment>
    <RegisterHeader/>
      <div className="py-5 px-2  d-flex align-items-center">
        <div className="container">
            <div className="card">
                <div className="card-body">
                    <h2 className="instruction-head text-center">APPLICATION GUIDELINES FOR RECRUITMENT INTO NIGERIA POLICE FORCE <br/> FOR SPECIALIST (ARTISAN) & GENERAL DUTY CONSTABLES</h2>
                    <p style={{ color: '#000', fontSize: 14 }}> The Police Service Commission (PSC) in conjunction with the Nigeria Police Force(NPF) invites applications from
                        interested and qualified Nigerians for enlistment into the Nigeria Police Force as Specialist (Artisan) & General Duty Police Constable (Recruits).
                    </p>
                    <p style={{ color: '#000', fontSize: 14 }}>The online application portal will
                        be opened for the period of six weeks from 15th October to 26th November  2023, where prospective applicants are required to complete the
                        online application forms and submitted within the specified period via this portal
                        The portal is open to both male and female Nigerians of good character.
                    </p>
                    <p style={{ color: '#000', fontSize: 14 }}>
                        Successful Applicants will undergo a combined Academic and Physical Training leading to the employment of successful ones into the
                        Nigeria Police Force as Specialist (artisan) Police Constables (PC).
                    </p>
                    <p style={{ color: '#000', fontSize: 14, fontWeight: "bold" }}>
                        THE RECRUITMENT EXERCISE IS FREE OF CHARGE
                    </p>
                    <b style={{ color: '#005bb9' }}> GENERAL REQUIREMENT / METHOD OF APPLICATION </b>
                    <div>
                        <ol>
                            <li>Applicants MUST have their National Identification Number (NIN), a Functional Email address and valid phone number before they commence the application.</li>
                            <li>Applicants are required to upload scanned copies of their SSCE, Birth and Certificates of Origin</li>
                            <li>Fill and submit the Online form(Review the information before final submission)</li>
                            <li>Applicant must print their completed online forms, guarantor forms and submit</li>
                            <li>Applicant must have passion for a career in the Nigeria Police Force</li>
                            <li>Applicants shall be of Nigerian origin by birth and possess National Identification Number (NIN) .</li>
                            <li>Applicants must be medically, physically, and psychologically fit and must not be less than 1.67mtrs tall for male and 1.64mtrs tall for female..</li>
                            <li>Applicants must not have less than 86cm (34 inches) expanded chest measurement (for men only)</li>
                            <li>Female Applicant must not be pregnant at the time of admission</li>
                            <li>Applicant must be free from any pecuniary embarrassment</li>
                        </ol>
                    </div>
                    <b style={{ color: '#005bb9' }}> GENERAL DUTY CONSTABLE REQUIREMENT </b>
                    <div>
                        <ol>
                            <li>Applicants must possess a minimum of 5 credits in not more than 2 sittings in WASSCE/GCE/NECO with credit passes in English and Mathematics not later than 2014</li>
                            <li>Applicants must be aged between 18-25 years.</li>
                        </ol>
                    </div>
                    <b style={{ color: '#005bb9' }}> SPECIALIST (ARTISAN) CONSTABLE REQUIREMENT </b>
                    <div>
                        <ol>
                            <li>Applicants must possess a minimum of 4 credits in not more than 2 sittings in WASSCE/GCE/NECO or its equivalent with credit passes in English and Mathematics not later than 2015</li>
                            <li>Applicants must be aged between 18-28 years.</li>
                            <li>Medical/Veterinary Assistants  must possess a minimum of 5 credits in not more than 2 sittings in WASSCE/SSCE or its equivalent with credit passes in English and Mathematics while other Certificates in relevant fields will be added advantage</li>
                            <li>Specialized Fields
                                <ul>
                                    <li>Medical Assistant (Junior Community Health Extension Workers (JCHEW)</li>
                                    <li>Veterinary Assistant (Relevant Certificate in Animal Health/Livestock) etc</li>
                                    <li>Band Section</li>
                                    <li>Communications/Info-Tech</li>
                                    <li>Driver/Mechanic</li>
                                    <li>Marine</li>
                                    <li>Plumbers</li>
                                    <li>Masons</li>
                                    <li>Painters</li>
                                    <li>Tilers</li>
                                    <li>Electricians</li>
                                    <li>Welders</li>
                                    <li>Carpenters</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        </div>

      <RegisterFooter/>
   </React.Fragment>
  )
}

export default Guidline
