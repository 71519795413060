import React, { FC } from 'react'
import './RegistrationStatusPass.scss'
import Table from 'react-bootstrap/esm/Table';
import { AgeValidator } from '../../../../../Helpers/AgeValidator';

interface propsType {
    value: any;
    disable: boolean;
}

const RegistrationStatusFail:FC<propsType> = ({ value, disable }) => {
  const {
    workflow,
    firstname,
    lastname,
    birthdate
  } = value

  const checkers = {
    AgeChecker: (data: any) => {
      const bday = AgeValidator(data)
      if (bday >= 26) {
        return true
      } else {
        return false
      }
    },
    HeightChecker: (data: any) => {
      if (data.height > 1.70) {
        return true
      } else {
        return false
      }
    },
    DisabilityChecker: (data: any) => {
      if (data.amputation === 'PASS' && data.deformed === 'PASS') {
        return true
      } else {
        return false
      }
    },
    ObeseChecker: (data: any) => {
      if (data.obese === 'PASS') {
        return true
      } else {
        return false
      }
    },
    TeethKneeFeetChecker: (data: any) => {
      if (data.knockknees === 'PASS' && data.flatfeet === 'PASS' && data.bknees === 'PASS') {
        return true
      } else {
        return false
      }
    },
    TattooChecker: (data: { tatto: string; }) => {
      if (data.tatto === 'PASS') {
        return true
      } else {
        return false
      }
    },
    KnowledgeAndExpressionChecker: (data: any) => {
      if (data.knowledge === 'PASS' && data.expression === 'PASS') {
        return true
      } else {
        return false
      }
    }
  }

  const notice = {
    age: "Above the required Age Limit",
    height: "Below the required Height",
    disability: "Below required fitness",
    obese: "Not fit for required health fitness",
    teethKneeFeet: "Not fit for required postural fitness",
    tattoo: "Not fit for required social fitness",
    knowledgeAndExpression: "Not fit for required mental fitness"
  }

  let message = ''
  switch (false) {
    case checkers.AgeChecker(birthdate):
      message = notice.age
      break;
    case checkers.HeightChecker(workflow.physicalScreening):
      message = notice.height
      break;
    case checkers.DisabilityChecker(workflow.physicalScreening):
      message = notice.disability
      break;
    case checkers.ObeseChecker(workflow.physicalScreening):
      message = notice.obese
      break;
    case checkers.TeethKneeFeetChecker(workflow.physicalScreening):
      message = notice.teethKneeFeet
      break;
    case checkers.TattooChecker(workflow.physicalScreening):
      message = notice.tattoo
      break;
    case checkers.KnowledgeAndExpressionChecker(workflow.physicalScreening):
      message = notice.knowledgeAndExpression
      break;
    default:
      message = "[]"
  }
  console.log(message)
  return (
    <div className="cbt-details my-5">
        {disable &&
        (<div className="">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="card bg-white p-2">
                  <div className="table-responsive">
                  <Table bordered>
                    <tbody>
                      <tr>
                      <td colSpan={2} className="p-0">
                        <div className="card-header text-center text-dark card-status-header px-5">
                              <h2 className="text-light font-weight-bolder cbt-title">Police Force Candidate Application Status</h2>
                         </div>
                      </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="d-flex justify-content-center align-item-center py-4">
                            <div className="text-center">
                             <h5>Dear {lastname} {firstname}</h5>
                             <p>Sorry, you are not qualified for the next stage of the 2022 Nigeria Police Force admission exercise because of the following reasons: </p>
                             {workflow.aptitude.reason.map((res:any, index:any) => {
                               return (<p className="text-danger text-" key={index}> - {res.toLocaleLowerCase()}</p>)
                             }
                             )}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                          <td className="text-center close-col" colSpan={2}>
                            <a href="/"><button className="btn btn--default btn-block">Close</button></a>
                            <div className="pcolor1 px-5">
                              <div className="section-color-tage1"></div>
                              <div className="section-color-tage2"></div>
                              <div className="section-color-tage3"></div>
                              <div className="section-color-tage4"></div>
                              <div className="section-color-tage5"></div>
                            </div>
                          </td>
                        </tr>
                      {workflow.aptitude.status !== 'qualified'
                        ? (<>
                      </>)
                        : (<></>)}

                    </tbody>
                  </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
    </div>
  )
}

export default RegistrationStatusFail
