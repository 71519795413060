import React, { useEffect, useState } from 'react'
import { Toast } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CollegeDetails, RegisterFooter, RegisterHeader } from '../../Components/Index'
import './CollegeSlip.scss'

const CollegeSlip = () => {
  const { refid } = useParams<any>()
  const history = useNavigate()

  // eslint-disable-next-line no-unused-vars
  const [disableStatus, setDisableStatus] = useState(false)
  const values = useSelector((state: { user:any }) => state.user.success)
  console.log('not needed', values)

  useEffect(() => {
    if (!values) {
      return history(-1)
    } else {
      setDisableStatus(true)
    }
  }, [refid, values])

  return (
    <div className="confirmation bg-light">
      <div className="register-main">
        <RegisterHeader></RegisterHeader>
          <div className="container">
              <div className="register-inner">
                  <div className="spacer"></div>

                  {/* {Object.keys(values).length !== 0 && values !== undefined && (<> */}
                    <CollegeDetails value={values} disable={disableStatus}/>
                  {/* </>)} */}
                  <div className="card">
                    <div className="card-body text-center">
                    <button onClick={() => window.print()} className="btn btn-dark"> Print Slip </button>
                    </div>
                  </div>
              </div>
        <Toast/>
      </div>
            <RegisterFooter></RegisterFooter>
    </div>

  </div>
  )
}

export default CollegeSlip
