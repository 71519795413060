import { Fragment, useState, useCallback, useEffect, FormEvent, SetStateAction } from 'react';
import axios from 'axios';
import { Toast } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { CbtDetails, RegisterHeader } from '../../Components/Index'
import './ExamSlip.scss'
import { BASE_URL_ADDR } from "../../../../Constants/BaseURL";
import Footer from "../../../../Layout/Footer";

const ExamSlip = () => {
  const { nin }:any = useParams();
  const [refInput, setRefInput] = useState<string>(nin || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationResults, setApplicationResults] = useState<any>();
  const [error, setError] = useState<any>();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    preview();
  }

  const changeRef = useCallback((e: { target: { value: SetStateAction<string>; }; }) => {
    setRefInput(e.target.value);
  }, [setRefInput]);

  useEffect(() => {
    if (nin) {
      preview();
    }
    return () => {}
  }, [nin])

  const preview = async () => {
    setLoading(true);

    axios({
      method: 'GET',
      url: `${BASE_URL_ADDR}/query-aptitude-status?nin=${refInput}`,
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(async response => {
      const data = response.data;
      setApplicationResults(data);
    }).catch(error => {
      setError(error?.response?.data?.message || "Application does no exist!");
      console.log(error);
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
      <Fragment>
          <RegisterHeader/>
          <main role="main" className="container examslip">
              {
                  (!applicationResults) &&
                  <section className="exam-slip">
                  <div className="row">
                    <div className="col-md-8 offset-md-2">
                      <div className="card border-0  rounded ">
                          <div className="card-header section-header-bg">
                              Print Your Examination Slip Here
                          </div>
                          <div className="card-body p-4 row">
                              <div className="col-md-6 offset-md-3  w-5 text-center">
                                  <form onSubmit={onSubmit}>
                                      {
                                          !nin &&
                                          <div className="form-group">
                                              <label htmlFor="refid">Enter your NIN (National Identification Number)</label>
                                              <input onChange={changeRef} value={refInput} type="text" id="refid" className="form-control refinput" disabled={loading} placeholder={ "Input your NIN here" }/>
                                              <p className="error-value" style={{ color: 'red' }}>{error}</p>
                                          </div>
                                      }
                                      <button disabled={loading} className="btn btn-warning text-dark btn-block shadow" type="submit" >Check Status { loading && <div style={{ marginTop: 10 }} className="spinner-border spinner-border-sm"></div>}</button>
                                  </form>
                              </div>
                          </div>
                        </div>
                      </div>
                      </div>
                  </section>
              }

              {
                  (applicationResults) &&
                  <section className="bg-light">
                      <div className="confirmation bg-light">
                          <div className="register-main">

                              <div className="container-md">
                                  <div className="register-inner">
                                      <div className="spacer"></div>
                                      <CbtDetails value={applicationResults} disable={true}/>
                                      <div className="card">
                                          <div className="card-body text-center">
                                              <button onClick={() => window.print()} className="btn btn-dark"> Print Slip </button>
                                          </div>
                                      </div>
                                  </div>
                                  <Toast/>
                              </div>

                          </div>
                      </div>
                  </section>
              }
          </main>
          <Footer />
      </Fragment>
  )
}

export default ExamSlip
