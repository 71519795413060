import React from 'react'
import { useForm } from 'react-hook-form'
import { RegisterHeader } from '../../../Components/Index'
import Footer from '../../../../../Layout/Footer';

interface formValues {
    nin:string;
    message:string;

  }
const ReportRejection = () => {
  const { handleSubmit, register, formState: { errors } } = useForm<formValues>()
  const submit = handleSubmit((data) => processData(data))

  const processData = (data: { [x: string]: any; }) => {
    // console.log(data)
  }

  return (
    <React.Fragment>
        <RegisterHeader/>
            <div className='container pt-4'>
                <h1> Report Recruitment Rejection</h1>
            </div>
            <div className="py-5 px-2  d-flex align-items-center">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                        <h3> Recruitment Rejection Reporting Form</h3>

                        <form onSubmit={submit}>
                            <div className="form-group">
                                <input type="text" className="form-control" {...register("nin")} placeholder="Your NIN number"/>
                                {errors.nin && (<div className="text-danger">{errors.nin.message}</div>)}
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" {...register("message")} placeholder="Your messages"></textarea>
                                {errors.message && (<div className="text-danger">{errors.message.message}</div>)}
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-warning text-dark px-5"> Report</button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        <Footer/>
     </React.Fragment>
  )
}

export default ReportRejection
