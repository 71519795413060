import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
// eslint-disable-next-line no-unused-vars
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../../../../../Components'
// eslint-disable-next-line no-unused-vars
import userService from '../../../../../Services/userService'
import { RegisterHeader } from '../../../Components/Index'
import './MedicalPrintSlip.scss'
import { useDispatch } from 'react-redux'
import { USER_FAILURE, USER_SUCCESS } from '../../../../../Constants/UserConstants'
import Footer from '../../../../../Layout/Footer'

interface FormValues {
  refId: string
}

const MedicalPrintSlip = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>()
  const history = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false)
  const dispatcher = useDispatch()

  const onSubmit = handleSubmit((data:any) => {
    setLoader(true)
    userService.getDataMedical(data.refId).then((res:any) => {
      if (res.data.dataset.formData.nin) {
        console.log(res.data.dataset.formData.nin)
        dispatcher({ type: USER_SUCCESS, data: res.data })
        history(`/medical-status/${res.data.dataset.formData.nin}`)
      } else {
        console.log(res)
        setLoader(false)
        history('/medical-status-failure/')
      }
    }, (error: any) => {
      dispatcher({ type: USER_FAILURE, data: error.message })
      setLoader(false)
      console.log(error)
      if (error.response.status === 500) {
        toast.error(error.response.data.message, { duration: 20000 })
      } else if (error.response.status === 404) {
        setLoader(false)
        history('/medical-status-failure/')
      } else {
        toast.error(error.message, { duration: 20000 })
      }
    })
  }
  )

  return (
    <React.Fragment>
        <div className="slip-confirmation h-100">
          <RegisterHeader></RegisterHeader>
            <div className="register-inner bg-light">
                 <div className="row h-100">
                   <div className="col-md-4 offset-md-4 my-auto">
                   <div className="card mt-5">
                    <div className="card-header text-center bg-white">
                      <div className="c-header-crib-2">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <h5>Medical Slip Printing</h5>
                      <div className="c-header-crib">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className="card-body">
                      <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label> National Identification Number (NIN)</label>
                          <input className="form-control" {...register('refId', { required: 'field cannot be empty' })}></input>
                          <div className="register--error text-danger">
                              {errors.refId && errors.refId.message}
                          </div>
                        </div>
                        <div className="form-group text-center">
                          <button className="btn btn-warning px-5 font-weight-bold text-dark">Check now</button>
                        </div>
                      </form>
                    </div>
                </div>
              </div>
            </div>
          </div>
      <Footer/>
    </div>
      <Loader show={loader}/>
      <Toaster/>
    </React.Fragment>
  )
}

export default MedicalPrintSlip
