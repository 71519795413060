import axios from 'axios';

axios.defaults.baseURL = 'https://api-stream.policerecruitment.gov.ng';
// axios.defaults.baseURL = 'https://plc-stream.policerecruitment.gov.ng';
// axios.defaults.baseURL = 'https://41.242.54.157:443';

axios.interceptors.request.use(function (config:any) {
  // Do something before request is sent
  config.headers.post['Content-Type'] = 'application/json';
  config.headers.common.Accept = 'application/json';
  config.params = { module: 'portal' };
  config.headers.post['Access-Control-Allow-Origin'] = "*";

  // console.log("heeeeehehehehhehheader", config)
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
axios.interceptors.response.use(function (config: any) {
  config.headers['cache-control'] = 'no-cache, no-store, must-revalidate';
  config.headers.Pragma = 'no-cache';
  config.headers.Expires = '0';

  return config;
}, function (error) {
  return Promise.reject(error)
});
const noteService = {
  saveNote: async (data:any) => {
    return await axios.post('/cpanel/save-notice-board', data)
  },
  fetchNote: async () => {
    return await axios.get('/cpanel/fetch-dynamic-page?command=notice-board')
  },
  admissionGuide: async () => {
    return await axios.get('/cpanel/fetch-dynamic-page?command=admission-guide')
  }
}

export default noteService
