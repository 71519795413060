import React from 'react'
import slider from "../../../../../Assets/images/police.jpeg"
import igPresident from "../../../../../Assets/images/polac-media9.jpeg"
import igp from "../../../../../Assets/images/igp.jpeg"

import "./Slider.scss"

const Slider = () => {
  return (
      <>
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="d-block w-100" src={slider} alt="First slide"/>
            <div className="carousel-caption d-nones d-md-block">
                <h5>Nigeria Police Force 2023 Recruitment</h5>
                <p>All applicant who applied and attended the physical screening exercise are advised to check their admission status and print their Computer Based Test (CBT) examination slip, if qualified for the next stage of the exercise.</p>
                <div>
                  <button className='btn btn-warning px-4 font-weight-bold text-dark'>More Details</button>
                </div>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={igp} alt="Second slide"/>
            <div className="carousel-caption d-nones d-md-block">
                <h5>Nigeria Police Force</h5>
                <p>All applicant who applied and attended the physical screening exercise are advised to check their admission status and print their Computer Based Test (CBT) examination slip, if qualified for the next stage of the exercise.</p>
                <div>
                  <button className='btn btn-warning px-4 font-weight-bold text-dark'>More Details</button>
                </div>
            </div>
          </div>
          <div className="carousel-item">
            <img className="d-block w-100" src={igPresident} alt="Third slide"/>
            <div className="carousel-caption d-nones d-md-block">
                <h5>Nigeria Police Force</h5>
                <p>All applicant who applied and attended the physical screening exercise are advised to check their admission status and print their Computer Based Test (CBT) examination slip, if qualified for the next stage of the exercise.</p>
                <div>
                  <button className='btn btn-warning px-4 font-weight-bold text-dark'>More Details</button>
                </div>
            </div>
          </div>
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
      </>
  )
}

export default Slider;
