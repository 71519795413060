import "./Footer.scss"
import React from "react";

const Footer = (year: any) => {
  return (
    <div className="footer-app">
        <div className="pcolorFooter">
            <div className="section-color-tage1"></div>
            <div className="section-color-tage2"></div>
            <div className="section-color-tage3"></div>
        </div>
      <div className="footer-bg-main m-0 p-4 text-light border-0" id="hide-on-print">
        <div className="container">
            <div className="row">
                <div className="col-md-3 text-center mt-2">
                    <i className="fa fa-map-marker fa-2x" aria-hidden="true"></i><br />
                    <h6 className="font-weight-bold text-white">PSC</h6>
                        <small>Police Service Commission, Corporate Headquarters</small> <br/>
                    <small>Plot 64, Cadastral Zone B16, Sector Centre B, Jabi, Abuja.</small>
                </div>
                <div className="col-md-3 text-center mt-2">
                        <i className="fa fa-phone fa-2x"></i><br />
                    <h6 className="font-weight-bold text-white">Call us</h6>
                        <small>08100004507</small>
                </div>
                <div className="col-md-3 text-center mt-2">
                    <div className=" text-wrap p-1">
                    <i className="fa fa-envelope fa-2x"></i><br />
                    <h6 className="font-weight-bold text-white">Send us a mail</h6>
                        <small>support@policerecruitment.gov.ng</small>
                    </div>
                </div>
                <div className="col-md-3 text-center mt-2">
                    <div><i className="fa fa-users fa-2x"></i></div>
                    <h6 className="font-weight-bold text-white">We are social</h6>
                    <i className="fab fa-facebook-square fa-1x m-1" ></i>
                    <i className="fab fa-linkedin fa-1x m-1"></i>
                    <i className="fab fa-twitter fa-1x m-1"></i>
                    <i className="fab fa-google fa-1x m-1"></i>
                    <i className="fab fa-instagram fa-1x m-1"></i>
                </div>
            </div>
        </div>
    </div>

    <div className=" m-0 p-0 foot-last border-0">
            <div className="container-fluid">
                    <div className="text-center font-weight-bolder text-warning p-ttop">
                            &copy; 2023, Nigeria Police Force - All Right Reserved.
                        <div className='font-weight-bolder font-size-16 text-warning'>Powered by: <span className='text-white'>Police Service Commission</span></div>
                        <div></div>
                    </div>
            </div>
    </div>

    </div>
  )
}

export default Footer;
