import React from 'react'
import Footer from '../../../../../Layout/Footer'
import { RegisterHeader } from '../../../Components/Index'

const PhysicalScreeningAcknowlegement = () => {
  return (
    <React.Fragment>
        <RegisterHeader/>
            <div className="py-5 px-2  d-flex align-items-center">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                        Physical Screening Acknowlegement
                        </div>
                    </div>
                </div>
            </div>
        <Footer/>
    </React.Fragment>
  )
}

export default PhysicalScreeningAcknowlegement
