import React from 'react'
import { RegisterHeader } from '../../../Components/Index'
import igPresident from "../../../../../Assets/images/p3.jpeg"
import "./NewsDetails.scss"
import Footer from '../../../../../Layout/Footer'
const NewsDetails = () => {
  return (
    <React.Fragment>
        <RegisterHeader/>
            <div className="pb-5 px-2  bg-white news-detail">

            <div className='container pt-4'>
                <h1>2022 NPF Recruitment Exercice begins</h1>
                <div className='card-news-date pt-0'>July 17, 2022</div>
            </div>

                <div className="container">
                    <div className="card shadow-none py-5">
                        <div className="card--img">
                           <img src={igPresident} alt="" className='w-100'></img>
                        </div>
                        <div className="card-body p-0 pt-5">
                            <p className="text-secondary font-weight-bolder">All empower batch c applicants are expected to update their profile and take test for possible shortlisting applicants are expected to update their profile and take test for possible shortlisting.
                            All empower batch c applicants are expected to update their profile and take test for possible shortlisting applicants are expected to update their profile and take test for possible shortlisting.</p>
                            <p className="text-secondary font-weight-bolder"> All empower batch c applicants are expected to update their profile and take test for possible shortlisting applicants are expected to update their profile and take test for possible shortlisting.</p>
                        </div>
                    </div>
                </div>
            </div>
        <Footer/>
    </React.Fragment>
  )
}

export default NewsDetails
