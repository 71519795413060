import React, { FC } from 'react'
import './MedicalStatusPass.scss'
import Table from 'react-bootstrap/esm/Table';

const MedicalStatusFail:FC = () => {
  return (
    <div className="cbt-details my-5">
     <div className="">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="card bg-white p-2">
                  <div className="table-responsive">
                  <Table bordered>
                    <tbody>
                      <tr>
                      <td colSpan={2} className="p-0">
                        <div className="card-header text-center text-dark card-status-header px-5">
                              <h2 className="text-light font-weight-bolder cbt-title">NPF Recruitment 2020 Candidate Medical Application Status</h2>
                         </div>
                      </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div className="d-flex justify-content-center align-item-center py-4">
                            <div className="text-center">
                             <h5>Dear Applicant</h5>
                             <p className="text-secondary">Sorry, you are not qualified for the next stage of the 2022 Police admission exercise. Thank you. </p>

                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                          <td className="text-center close-col" colSpan={2}>
                            <a href="/"><button className="btn btn--default btn-block">Close</button></a>
                            <div className="pcolor1 px-5">
                              <div className="section-color-tage1"></div>
                              <div className="section-color-tage2"></div>
                              <div className="section-color-tage3"></div>
                              <div className="section-color-tage4"></div>
                              <div className="section-color-tage5"></div>
                            </div>
                          </td>
                        </tr>

                    </tbody>
                  </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default MedicalStatusFail
