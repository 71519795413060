/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Footer from '../../../../../Layout/Footer'
import { RegisterHeader } from '../../../Components/Index'
import applicantsService from '../../../../../Services/applicantsService'
import toast from 'react-hot-toast'
// import { Loader1 } from '../../../../../Components'
// import { useNavigate } from 'react-router-dom'
// import { Loader, Loader1 } from '../../../../../Components'

const DownloadShortlisted = () => {
  const [dlsit, setDlist] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  //   const navigate = useNavigate()
  const fetchList = async () => {
    setLoading(true)
    try {
      const response = await applicantsService.downloadShortlisted()
      const url: string = response.data.data
      setDlist(url)
      window.location.href = url
      setLoading(false)
      toast.success("Download List now Available")
    } catch (error: any) {
      setLoading(false)
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchList()
  }, [])
  return (
        <React.Fragment>
            <RegisterHeader />
            <div className="py-5 px-2  d-flex align-items-center">
                <div className="container">
                    <div className="card">
                        <div className="card-body">
                            Download Shortlisted
                            <hr />
                        </div>
                        <div className='m-5 d-flex justify-content-center w-100'>
                        <button className='btn btn-primary fw-bolder' onClick={() => fetchList()}>       Download Shortlisted</button>

                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </React.Fragment >
  )
}

export default DownloadShortlisted

// {
//   loading
//     ? <><div className="spinner-border" role="status">
//     {/* <span className="visually-hidden">Loading...</span> */}
//   </div></>
//     : <> {
//           dlsit
//             ? <div className='w-110'>
//             <div className='text-center fw-bold fs-3'> Click here to download  </div>
//                  <div className="d-flex justify-content-center mt-4">
//                   < button className='btn btn-success fw-bolder' onClick={() => downloadList()}> Download List </button>
//                   </div>
//               </div>
//             : <div className='w-100 '>
//             <div className='text-center fw-bold fs-3'>Click here to fetch list</div>
//             <div className='d-flex justify-content-center mt-4'>
//               <button className='btn btn-primary fw-bolder' onClick={() => fetchList()}>Fetch List</button></div>
//             </div>
//             }
//       </>
// }
