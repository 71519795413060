import { useEffect, useState } from "react"
import noteService from "../../../../Services/noticeboard"
import Announcement from '../../../../Data/regcardlist.json'
import "./RegProcess.scss"
import { RegisterHeader } from "../../Components/Index"
import Footer from "../../../../Layout/Footer"
import { useNavigate } from "react-router-dom"
import { Modal } from "react-bootstrap"
export default function RegProcess () {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  // const [announcementN, setAnnouncement] = useState<any>()
  const announcementNotice = async () => {
    try {
      const noticeBoard = await noteService.fetchNote()
      if (noticeBoard.data.dataset) {
        // console.log(noticeBoard.data)
        // setAnnouncement(noticeBoard.data.dataset)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [noticeData, setNoticeData] = useState<any | string>()
  const fetchNotice = async () => {
    await noteService.admissionGuide().then((res) => {
      console.log("fetchNotice", res)
      setNoticeData(res.data.dataset)
      setShow(true)
    }, error => {
      console.log("fetchNotice error", error)
    })
  }

  const navigate = useNavigate()
  const downloadForm = () => {
    window.location.href = "/media/GUARANTOR-FORM-PSC.pdf"
  }
  useEffect(() => {
    announcementNotice()
  })
  return (
    <>
      <RegisterHeader />
      <div className="container regProcess mt-5 mb-5">
        <header>
          <div className="row row-cols-lg-2  row-cols-1">
            <div className="col">
              <figcaption className="blockquote-footer">
                Application Excercise Process
              </figcaption>
              <h3 className="text-uppercase npf-heading">Nigeria POLICE FORCE</h3>
              <div>
                {/* <div className="row">
                  <div className="col">Timer: </div>
                  <div className="col"></div>
                </div> */}
                <div className="container mt-3">
                  <div className="row status-style rounded-pill">
                    <div className="col rounded-pill btn-danger border border-white border-5 h5 text-white text-center m-1 p-2" data-testid='status-div'> Not Available </div>
                    <div className="col text-dark text-center m-1 p-2 h5" data-testid='date-div'> coming soon </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div>
          <div className="mt-5 mb-3">
            <h3 className="app-Process__style">Application Process</h3>
          </div>
          <div className="row row-cols-1 row-cols-lg-2 ">
            {Announcement.map((item: any, index: number) => {
              return (
                <div className="col gap-1" key={index}>
                  <div className="card rounded-5 shadow-0 regProcess--hover col " key={index} >
                    <div className="card-body ">
                      <div>
                        <div className="ribbon">
                          <p className="text-white p-2">Step</p>
                          {item.id}
                        </div>
                        <div className="row container   ">
                          <div><h3 className="text-item-title-style text-uppercase">{item.title}</h3></div>
                        </div>
                      </div>
                      <div className="card-height   align-content-end"><p className=" text-item__description-style  text-start">{item.description}</p>
                        {item['button-title'] && <div>
                          {
                            item.route !== "/media/GUARANTOR-FORM-PSC.pdf"
                              ? <button className="btn btn-success" onClick={() => { item['button-title'] === "Read Information" ? fetchNotice() : item.route !== "/media/GUARANTOR-FORM-PSC.pdf" ? navigate(item.route) : navigate('') }}> {item['button-title']}</button>
                              : <button className="btn btn-success" onClick={() => { downloadForm() }} > {item['button-title']}</button>
                          }

                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            }
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Dialog >
          <h4 id="instruction-title" dangerouslySetInnerHTML={{ __html: noticeData?.subject }} />
          <p style={{ color: 'black' }}> <div dangerouslySetInnerHTML={{ __html: noticeData?.body }} /></p>
        </Modal.Dialog>
      </Modal>
      <Footer />
    </>
  )
}
