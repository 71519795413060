import React, { Fragment, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RegisterHeader } from '../../../Components/Index'
import './MedicalSlip.scss'
import userService from '../../../../../Services/userService'
import { USER_FAILURE, USER_SUCCESS } from '../../../../../Constants/UserConstants'
import Footer from '../../../../../Layout/Footer'
import { Toaster } from 'react-hot-toast'
import { Loader } from '../../../../../Components'

const MedicalSlip = () => {
  const { refid } = useParams<any>()
  const dispatcher = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [disableStatus, setDisableStatus] = useState(false)
  const [values, setValue] = useState<any>()
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false)
  const [admissionInfo, setAdmissionInfo] = useState<string | any>()
  const [hideButton, setHiddenButton] = useState<boolean>(false)

  const { success, loading } = useSelector((state: { user: any }) => state.user)
  // console.log(success)
  useEffect(() => {
    if (typeof success === "undefined" && typeof success !== "object") {
      setLoader(true)
      userService.getDataMedical(refid).then((res: any) => {
        setLoader(false)
        dispatcher({ type: USER_SUCCESS, data: res.data });
        setValue(res.data)
      }, (error: { messgae: string }) => {
        dispatcher({ type: USER_FAILURE, data: error.messgae });
        window.location.href = "/"
      })
    }
  }, [])

  if (loading && loading === true) {
    setLoader(true)
  }

  useEffect(() => {
    if (typeof success !== "undefined") {
      setValue(success)
    }
  }, [success])
  useEffect(() => {
    const dateEx: string | null = localStorage.getItem("MENU_COMMAND")
    const dataJson = dateEx !== null && JSON.parse(dateEx)
    const dateJson = dataJson.data.admissionYear
    setAdmissionInfo(dateJson)
  }, [])
  return (
  //   <div className="confirmation bg-light">
  //     <div className="register-main">
  //       <RegisterHeader></RegisterHeader>
  //         <div className="container">
  //             <div className="register-inner">
  //                 <div className="spacer"></div>

    //                 {values && Object.keys(values).length !== 0 && values !== undefined && (<>
    //                   <MedicalDetails value={values} disable={disableStatus}/>
    //                 </>)}
    //                 <div className="card">
    //                   <div className="card-body text-center">
    //                   <button onClick={() => window.print()} className="btn btn-primary"> Print Slip </button>
    //                   </div>
    //                 </div>
    //             </div>
    //       <Toast/>
    //     </div>
    //         <Footer/>
    //   </div>
    //   <Loader show={loader}/>
    // </div>
    <React.Fragment>
      <RegisterHeader />
      {values
        ? <div className='pSchedule'>
          <div className="cbt-details my-5">
            <div className="">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <div className="card bg-white p-2">
                      <div className="table-responsive overflow-hidden">
                        <Table bordered className='rounded-5'>
                          <tbody>
                            <tr>
                              <td colSpan={2} className="p-0">
                                <div className="card-header text-center text-dark card-status-header px-5">
                                  <div className='row'>
                                    <div className='col'>
                                      <div className='row'>
                                        <div className="col d-flex justify-content-end">
                                          <img className="img-responsive rounded-circle" width='100' height='100' src='psc_logo.png' />
                                        </div>
                                        <div className="col d-flex justify-content-start">
                                          <img className="img-responsive" width='100' height='100' src='police_logo.png' />
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                  <div className='mb-0 pb-0 pt-4'>
                                    <h1 className="text-light font-weight-bolder cbt-title fs-1 p-0"> {admissionInfo || "2022"} Nigeria Police Force Recruitment</h1></div>

                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={2}>
                                <div className='mt-0 pt-0'><h5 className="text-dark text-center pt-2 ">{admissionInfo || "2022"}  Schedule Slip</h5></div>
                                <div className="d-flex justify-content-center py-4 row">
                                  <div className='col justify-content-end d-flex'>
                                    <img className="img-responsive" width='120' height='120' src={`data:image/png;base64, ${values?.photo !== undefined ? values?.photo : ''}`} />
                                  </div>
                                  <div className='col align-self-center'>
                                    <div className="text-secondary font-weight-bolder fs-5 text-start text-name">NAME: {values?.dataset?.formData?.surname || ''} {values?.dataset?.formData?.firstname} {values?.dataset?.formData?.middlename}</div>
                                    <div className="text-secondary font-weight-bolder fs-5 text-start">NIN: {values?.dataset.formData.nin}</div>
                                    {values?.dataset?.workflow?.medical?.metadata?.initialized === 1
                                      ? <div className="text-success font-bold font-weight-bold">STATUS: {values?.dataset?.workflow?.medical.metadata.initialized === 1 && "Qualified"}</div>
                                      : <div className="text-danger font-bold font-weight-bold">STATUS: {values.dataset.workflow.medical.metadata.initialized === 0 && "Not qualified"}</div>}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr className='cand-info-bg'>
                              {
                                values?.dataset?.workflow?.medical?.metadata?.initialized === 1
                                  ? <td colSpan={2}>{values?.dataset?.workflow?.aptitude?.scheduleSchema
                                    ? <>
                                      <div className='pt-3 '>
                                        <div className="text-center"><b>CBT Date:</b></div>
                                        <div className='text-center info-text'>{values?.dataset?.workflow?.aptitude?.scheduleSchema["medical-date"] || "Not Avaliable"}</div>
                                      </div>
                                      <div className='pt-3 '>
                                        <div className="text-center"><b>CBT Time:</b></div>
                                        <div className='text-center info-text'>{values?.dataset?.workflow?.aptitude?.scheduleSchema["medical-time"] || "Not Avaliable"}</div>
                                      </div>
                                      <div className='pt-3 '>
                                        <div className="text-center"><b>CBT VENUE:</b></div>
                                        <div className='text-center info-text'>{values?.dataset?.workflow?.aptitude?.scheduleSchema["medical-venue"] || "Not Avaliable"}</div>
                                      </div>
                                    </>
                                    : <>
                                      <h5 className='text-center '>Medical Scheduled Credential not Available</h5>
                                    </>
                                  } </td>
                                  : <td colSpan={2}>
                                    <h5 className='text-danger text-center'>Disqualified</h5>
                                    {
                                      values?.dataset?.workflow?.reasonForDisqualify && values?.dataset?.workflow?.reasonForDisqualify.map((reason: any, index: number) => (

                                        <Fragment key={index + 1}>
                                          <h5 className='text-center'>{reason}</h5>
                                        </Fragment>

                                      ))
                                    }

                                  </td>
                              }

                            </tr>
                            <tr>
                              <td>
                                {
                                  values?.dataset?.workflow?.aptitude?.scheduleSchema && <div className='row mt-5'>
                                    <div className="text-center col-5"><img className="img-responsive" src={`data:image/png;base64, ${values?.authQR}`} width={200} height={200} alt='qrcode' /></div>
                                    <div className="text-start col mt-4 d-flex align-self-center">Please come along with a copy of this slip during the Aptitude exercise.</div>

                                  </div>
                                }
                              </td>
                            </tr>

                            <tr>
                              <td className="text-center close-col" colSpan={2}>
                                <div className="pcolor1 px-5">
                                  <div className="section-color-tage1"></div>
                                  <div className="section-color-tage2"></div>
                                  <div className="section-color-tage3"></div>
                                  <div className="section-color-tage4"></div>
                                  <div className="section-color-tage5"></div>
                                </div>
                              </td>
                            </tr>

                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='pt-3 pb-5 justify-content-center d-flex'>
                <button className={hideButton ? "d-none" : "btn w-25 btn--default btn-block"} onClick={() => { setHiddenButton(true) }}>Print</button>
              </div>
            </div>

          </div>
        </div>
        : <Loader show={loader} />
      }
      <Footer />
      <Toaster></Toaster>

    </React.Fragment>
  )
}

export default MedicalSlip
