import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { RegisterHeader } from '../../Components/Index'
import './Contact.scss'
import emailjs from 'emailjs-com';
import toast, { Toaster } from 'react-hot-toast';
import Footer from '../../../../Layout/Footer';

interface formValues {
    name: string;
    email: string;
    message: string;
  }

const Contact = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<formValues>();

  const [submited, setSubmited] = useState(false)

  const onSubmit = handleSubmit((data) => completeForm(data));

  async function completeForm (data:any) {
    setSubmited(true)
    console.log("form data", data)
    await emailjs.send('service_0h4osmf', 'template_s3mdl7g', data, 'user_3MVlIXFuHooPMmVl085cW')
      .then((result:any) => {
        setSubmited(false)
        toast.success('Message sent successfully', { duration: 20000, className: 'bg-success text-white' });
      }, (error:any) => {
        setSubmited(false)
        toast.error(error.message, { duration: 20000, className: 'bg-danger text-white' });
      });
  }

  return (
    <React.Fragment>
    <RegisterHeader/>
    <div className="contact-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.118237445449!2d7.505296514495244!3d9.052978393504247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0a4ce735ee95%3A0x6b62cf6341ea5d9b!2sNigerian%20Police%20Headquarters%2C%20Louis%20Edet%20House!5e0!3m2!1sen!2sng!4v1634017783089!5m2!1sen!2sng" width="100%" height="500px" style={{ border: 0 }} loading="lazy"></iframe>
    </div>
     <div className="contact">

        <section className="section section-montserrat container-fluid my-5 pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg">
                        <div className="heading-wrap mb-5">
                            <h2 className="heading-wrap__heading heading-wrap__heading--2">Contact Us</h2>
                            <p>Reach out to us for any enquiry</p>
                        </div>

                        <div className="contact-form mb-3">
                            <form onSubmit={onSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Full Name</label>
                                    <input type="text" className="form-control" id="name" {...register("name", { required: "field cannot be empty" })} placeholder="Full Name"/>
                                    <div className="text-danger"> {errors.email && errors.email.message} </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email Address</label>
                                    <input type="email" className="form-control" id="email" {...register("email", { required: "field cannot be empty" })} aria-describedby="emailHelp" placeholder="Email Address"/>
                                    <small id="emailHelp" className="form-text text-muted">We&apos;ll never share your email with anyone else.</small>
                                    <div className="text-danger"> {errors.email && errors.email.message} </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea className="form-control" id="message" {...register("message", { required: "field cannot be empty" })} placeholder="Message"></textarea>
                                    <div className="text-danger"> {errors.message && errors.message.message} </div>
                                </div>

                                {/* {!submited && (<button type="submit" className="btn btn--default info btn--primary-light">
                                    Submit
                                 </button>)} */}
                                {!submited && (<button type="submit" className="btn btn--default info btn-warning text-dark shadow rounded">
                                    Submit
                                 </button>)}

                                {submited && (<button type="submit" className="btn btn--default info btn--primary-light" disabled>
                                    Please wait
                                    <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
                                </button>)}
                            </form>
                        </div>
                    </div>

                    <div className="col-lg">
                        <div className="container">
                            <div className="row bg-white">
                                <div className="col-md-12">
                                <div className="contact-info__card text-center  bg-white">
                                        <div className="icon-wrap icon-wrap--grey d-flex align-items-center justify-content-center mx-auto">
                                            <i className="icon-round jobs__list--icon fas fa-map-marked-alt fa-2x"></i>
                                        </div>
                                        <div className="contact-info__details">
                                            <h3 className="contact-info__heading">Location Here</h3>
                                            <p>
                                                <a href="https://www.google.com/maps/place/Abuja/" target="_blank" rel="noopener noreferrer">Louis Edet House Garki, Shehu Shagari Way, <br/>Abuja</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="contact-info__card text-center bg-white">
                                        <div className="icon-wrap icon-wrap--grey d-flex align-items-center justify-content-center mx-auto">
                                            <i className="icon-round jobs__list--icon fas fa-envelope-open-text fa-2x"></i>
                                        </div>
                                        <div className="contact-info__details">
                                            <h3 className="contact-info__heading">Email Here</h3>
                                            <p>
                                                <a href="mailto:support@policerecruitment.gov.ng" target="_blank" rel="noopener noreferrer">support@policerecruitment.gov.ng</a>
                                            </p>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-6">
                                    <div className="contact-info__card text-center bg-white">
                                        <div className="icon-wrap icon-wrap--grey d-flex align-items-center justify-content-center mx-auto">
                                            <i className="icon-round jobs__list--icon fas fa-phone-volume fa-2x"></i>
                                        </div>
                                        <div className="contact-info__details">
                                            <h3 className="contact-info__heading">Call Here</h3>
                                            <p>
                                                <a href="tel:+2348100004507" target="_blank" rel="noopener noreferrer">08100004507</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>

    <Footer/>

    <Toaster/>
   </React.Fragment>
  )
}

export default Contact
