import React, { FC } from 'react'
import emptyAvarter from "./../../../../../Assets/images/user-empty-avatar.png";

interface propsType {
    value: any;
    disable: boolean;
}

const ProfilePhotoPreview:FC<propsType> = ({ value, disable }) => {
  // eslint-disable-next-line no-unused-vars
  const { reference_id, document, workflow, photo } = value
  // console.log("value value", value)
  return (
    <div className="card mt-4">
      <div className="card-header cbt-heading text-light text-center font-weight-bolder">
            <div className="c-header-crib-2">
                <span></span>
                <span></span>
            </div>
            CONFIRMATION SLIP
            <div className="c-header-crib">
                <span></span>
                <span></span>
            </div>
        </div>

        {disable &&
        (<div className="card-body">
          <div className="container">
              <div className="row h-100">
                  <div className="col-md-6 position-relative">
                    <div className="text-left mt-4">
                        <b>Application Reference ID: </b>
                        <div style={{ fontWeight: 400, textAlign: "left" }}>{reference_id}</div>
                    </div>
                    <div className="position-absolute fixed-bottom text-left">
                      <h2>{workflow.aptitude.qualified ? <span className="text-success">Qualified</span> : <span className="text-danger">Not Qualified</span>}</h2>
                    </div>
                  </div>
                  <div className="col-md-6 text-center position-relative">
                    <div>
                    {photo
                      ? <img src={`data:image/png;base64, ${photo}`} alt="" className="w-50 border rounded"/>
                      : <img src={emptyAvarter} alt="" className="w-50 border rounded"></img>
                        }
                    </div>
                  </div>
              </div>
          </div>
        </div>
        )}
    </div>
  )
}

export default ProfilePhotoPreview
