import axios from 'axios';

axios.defaults.baseURL = 'https://api-stream.policerecruitment.gov.ng';
// axios.defaults.baseURL = 'https://plc-stream.policerecruitment.gov.ng';
// axios.defaults.baseURL = 'https://41.242.54.157:443';

axios.interceptors.request.use(function (config:any) {
  // Do something before request is sent
  config.headers.post['Content-Type'] = 'application/json';
  config.headers.common.Accept = 'application/json';
  config.headers.post['Access-Control-Allow-Origin'] = "*";
  // console.log("heeeeehehehehhehheader", config)
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

const userService = {

  saveRegistrationData: async (refId:string, data:{}) => {
    return await {}
  },

  getData: async (ref:any) => {
    return await {};
  },
  getDataAx: async (ref:string) => {
    return await axios.get(`/query-nin-status?nin=${ref}&module=portal&admissionId=3`);
  },

  getDataMedical: async (ref:any) => {
    return await axios.get(`/query-medical-status?nin=${ref}&module=portal&admissionId=3`);
  },

  getDataSingle: async (ref:string) => {
    return await axios.get(`/cpanel/query?nin=${ref}&module=portal&admissionId=3`);
  },

  getDataByEmail: async (email:string) => {
    return await {};
  },

  getMenuCommand: async () => {
    return await axios.get('/cpanel/module/status-info');
  },

  getDataByRef: async (email:string) => {
    return await {};
  },

  getDataByPhone: async (phone:number) => {
    return await {};
  },

  validateEmail: async (email:string) => {
    return await {};
  },

  validatePhone: async (phone:number) => {
    return await {};
  },
  totalApplicant: async () => {
    return await {};
  },
  updateProfilePics: async (data:{hashRef:string, imageData:any}) => {
    return await {};
  },

  updateCbtVenue: async (data:{hashRef:string, data:any}) => {
    return await {};
  },

  updateApplicantData: async (data:any) => {
    return await axios.post('/save-nin-status', data);
  },

  updateApplicantMedData: async (data:any) => {
    return await axios.post('/save-medical-status', data);
  },
  collegeConf: async (ref:string) => {
    // return await axios.get(`/query-nin-status?nin=${ref}`);

    return await axios.get(`/query-recruit-status?nin=${ref}`);
  },
}

export default userService
