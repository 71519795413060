import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
// eslint-disable-next-line no-unused-vars
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../../../../Components'
// eslint-disable-next-line no-unused-vars
import userService from '../../../../Services/userService'
import { RegisterFooter, RegisterHeader } from '../../Components/Index'
import './ConfirmationSlipVerification.scss'
import { useDispatch } from 'react-redux'
import { USER_FAILURE, USER_SUCCESS } from '../../../../Constants/UserConstants'

interface FormValues {
  refId: string
}

const ConfirmationSlipVerification = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>()
  const history = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false)
  const dispatcher = useDispatch()

  const onSubmit = handleSubmit((data:any) => {
    setLoader(true)
    userService.getDataAx(data.refId).then((res:any) => {
      // toast.loading('The system is currently collating data. Please try again...');
      console.log('better', res)
      if (res.data.nin) {
        dispatcher({ type: USER_SUCCESS, data: res.data })
        history('/exam-slip/' + res.data.nin)
      } else {
        setLoader(false)
        toast.error("Phone or NIN doesn't exist", { duration: 4000 })
      }
    }, (error: any) => {
      console.log('Alas Error:', error.message)

      dispatcher({ type: USER_FAILURE, data: error.message })
      setLoader(false)
      toast.error(error.message, { duration: 4000 })
    })
  }
  )

  return (
    <React.Fragment>
        <div className="slip-confirmation h-100">
          <RegisterHeader></RegisterHeader>
            <div className="register-inner bg-light">
                 <div className="row h-100">
                   <div className="col-md-4 offset-md-4 my-auto">
                   <div className="card mt-5">
                    <div className="card-header text-center bg-white">
                      <div className="c-header-crib-2">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                      <h5>Confirmation Slip Printing</h5>
                      <div className="c-header-crib">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className="card-body">
                      <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <label> National Identification Number (NIN)</label>
                          <input className="form-control" {...register('refId', { required: 'field cannot be empty' })}></input>
                          <div className="register--error text-danger">
                              {errors.refId && errors.refId.message}
                          </div>
                        </div>
                        <div className="form-group text-center">
                          <button className="btn btn-outline-success">Print Slip</button>
                        </div>
                      </form>
                    </div>
                </div>
              </div>
            </div>
          </div>
      <RegisterFooter></RegisterFooter>
    </div>
      <Loader show={loader}/>
      <Toaster/>
    </React.Fragment>
  )
}

export default ConfirmationSlipVerification
