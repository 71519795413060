import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './index.scss'
import reportWebVitals from './reportWebVitals'

import {
  Home,
  ConfirmationSlip,
  ConfirmationSlipVerification,
  Close,
  ConfirmationSlipCollege,
  RegStatusVerification,
  RegistrationStatus,
  ExamSlip,
  CollegeSlip,
  MedicalStatusVerification,
  MedicalStatus,
  MedicalSlip,
  Guidline,
  News,
  NewsDetails,
  PrintScreenStatus,
  PhysicalScreeningAcknowlegement,
  StatusUploadRequest,
  ReportWrongScheduleMedical,
  ReportWrongSchedule,
  PrintAptitudeSlip,
  DownloadShortlisted,
  ReportWrongScheduleAdmission,
  ReportRejection,
  MedicalPrintSlip,
  VerifyRrrPayment,
  StartApplcation,
  MedicalStatusFailure,
  PrintSlip,
  PrintSpecialistSlip
} from './Pages/Frontend/Interfaces'
import { Provider } from 'react-redux'
import Application from './Pages/Frontend/Interfaces/Application/Application'
import Contact from './Pages/Frontend/Interfaces/Contact/Contact'
import NotFound from './Pages/Frontend/Interfaces/404/NotFound'
import Store from './Storage/Store'
import ManagedRoutes from './Components/RoutesAccessControl/manageRoutes'
import InstitutionPrintSlip from './Pages/Frontend/Interfaces/AcademyAdmission/InstitutionPrintSlip/InstitutionPrintSlip'
import RegProcess from './Pages/Frontend/Interfaces/Admission/admission'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
<React.StrictMode>
      <Provider store={Store}>
        <BrowserRouter>
          <Routes>
            <Route path="/close-home" element={<ManagedRoutes><Home/></ManagedRoutes>}></Route>
            <Route path="/contact" element={<ManagedRoutes><Contact/></ManagedRoutes>}></Route>
            <Route path="/jhome" element={<RegProcess/>}></Route>

            {/* <Route path="/applyp" element={<Application/>}></Route> */}
            <Route path="/apply" element={<StartApplcation />}></Route>
            <Route path="/" element={<StartApplcation />}></Route>
            <Route path="/verify-payment" element={<ManagedRoutes><VerifyRrrPayment/></ManagedRoutes>}></Route>

            <Route path="/guideline" element={<ManagedRoutes><Guidline/></ManagedRoutes>}></Route>
            <Route path="/slip/:refid" element={<ManagedRoutes><ConfirmationSlip/></ManagedRoutes>}></Route>
            <Route path="/exam-slip/:nin?" element={<ManagedRoutes><ExamSlip/></ManagedRoutes>}></Route>
            <Route path="/college-slip/:refid" element={<CollegeSlip/>}></Route>
            <Route path="/status/nin/verification" element={<RegStatusVerification/>}></Route>
            <Route path="/status/:refid" element={<RegistrationStatus/>}></Route>
            <Route path="/slip/nin/verification" element={<ConfirmationSlipVerification/>}></Route>
            <Route path="/slip/print/college" element={<ConfirmationSlipCollege/>}></Route>
            <Route path="/close" element={<Close/>}></Route>
            <Route path="/register-closed-for-31230989" element={<Application/>}></Route>
            <Route path="/verified-application" element={<Application/>}></Route>

            <Route path="/print-slip">
              <Route path=":nin" element={<PrintSlip />} />
              <Route index element={<PrintSlip />} />
            </Route>

            <Route path="/news" element={<News/>}></Route>
            <Route path="/news-detail" element={<NewsDetails/>}></Route>

            {/* physical screening */}

            <Route path="/physical-screening-acknowlegement" element={<ManagedRoutes><PhysicalScreeningAcknowlegement/></ManagedRoutes>}></Route>
            <Route path="/print-screen-status" element={<ManagedRoutes><PrintScreenStatus/></ManagedRoutes>}></Route>
            <Route path="/status-upload-request" element={<ManagedRoutes><StatusUploadRequest/></ManagedRoutes>}></Route>

            {/* aptitude */}
            <Route path="/print-aptitude-slip" element={<ManagedRoutes><PrintAptitudeSlip/></ManagedRoutes>}></Route>
            <Route path="/print-speciality-slip" element={<ManagedRoutes><PrintSpecialistSlip/></ManagedRoutes>}></Route>
            <Route path="/report-wrong-schedule" element={<ManagedRoutes><ReportWrongSchedule/></ManagedRoutes>}></Route>

            {/* Medical */}
            <Route path="/report-wrong-schedule-medical" element={<ManagedRoutes><ReportWrongScheduleMedical/></ManagedRoutes>}></Route>
            <Route path="/medical-slip/:refid" element={<ManagedRoutes><MedicalSlip/></ManagedRoutes>}></Route>
            <Route path="/medical-print-slip" element={<ManagedRoutes><MedicalPrintSlip/></ManagedRoutes>}></Route>

            <Route path="/medical-status/:refid" element={<ManagedRoutes><MedicalStatus/></ManagedRoutes>}></Route>
            <Route path="/status-nin-medical" element={<ManagedRoutes><MedicalStatusVerification/></ManagedRoutes>}></Route>
            <Route path="/medical-status-failure" element={<ManagedRoutes><MedicalStatusFailure/></ManagedRoutes>}></Route>

            {/* Institution */}
            <Route path="/institution-print-slip" element={<ManagedRoutes><InstitutionPrintSlip/></ManagedRoutes>}></Route>
            <Route path="/report-rejection" element={<ManagedRoutes><ReportRejection/></ManagedRoutes>}></Route>
            <Route path="/report-wrong-schedule-admission" element={<ManagedRoutes><ReportWrongScheduleAdmission/></ManagedRoutes>}></Route>
            <Route path="/download-shortlisted" element={<ManagedRoutes><DownloadShortlisted/></ManagedRoutes>}></Route>

            <Route path="*" element={<NotFound/>}> </Route>

            </Routes>
        </BrowserRouter>
      </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
