import React, { FC, useState } from 'react'
import './RegistrationStatusPass.scss'
import emptyAvarter from "./../../../../../Assets/images/user-empty-avatar.png";
import Table from 'react-bootstrap/esm/Table';
import { useForm } from "react-hook-form";
import userService from '../../../../../Services/userService';
import { useParams } from 'react-router';
import { Loader } from "../../../../../Components"
import { Toaster, toast } from 'react-hot-toast';
import states from '../../../../../Data/state.json'
import lgas from '../../../../../Data/lga.json'

interface propsType {
    value: any;
    disable: boolean;
}

interface formValues {
  email:string;
  phone:string;
  stateOfOrigin: string;
  mobile: string;
  state: string;
  lga:string;
  mobile1:string;
}
const RegistrationStatusPass:FC<propsType> = ({ value, disable }) => {
  const {
    workflow,
    lastname,
    firstname,
    reference_id,
    dob,
    email,
    phone,
    mobile,
    lga,
    state,
    photo
  } = value
  const { register, handleSubmit, formState: { errors } } = useForm<formValues>();
  const [loader, setLoader] = useState(false)

  const initialState = {
    phone,
    mobile,
    email,
    lga,
    state,
    photo
  }

  const [fields, updateFields] = useState<any>(initialState)
  const [submitBtn, setSubmitBtn] = useState<boolean>(true)

  const { refid } = useParams<any>()

  const selectedLga = lgas[2].data?.filter((lgase: { state_id: any; }) => {
    return lgase.state_id === fields.state;
  });

  // eslint-disable-next-line no-unused-vars
  const [uploadStatus, setUploadStatus] = useState<boolean>(false)
  // const [disbledBtn, setDisbledBtn] = useState<boolean>(false)

  const uploadImage = async (event: { target: { files: any; }; }) => {
    setUploadStatus(true)

    const file = event.target.files

    function isImage (icon:any) {
      const ext = ['.jpg', '.jpeg', '.gif', '.png'];
      return ext.some(el => icon.endsWith(el));
    }
    if (!isImage(file[0].name)) {
      alert("File extension not supported!");
      return false
    }

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);

    reader.onload = async (e:{target:any}) => {
      const base64result = e.target.result?.split(',')[1];
      // const base64result = e.target.result;
      updateFields({
        ...fields,
        photo: base64result,
      })
      setUploadStatus(false)
    }
  }

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    updateFields({
      ...fields,
      [name]: value,
    })
  }

  if (mobile !== null &&
      email !== null &&
      lga !== null &&
      state !== null &&
      photo) {
    window.location.href = "/exam-slip/" + refid
  }

  function titleCase (str: string) {
    const strArr = str.toLowerCase().split(' ');
    for (let i = 0; i < strArr.length; i++) {
      strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].slice(1);
    }
    return strArr.join(' ');
  }

  const onSubmit = handleSubmit(async () => {
    setLoader(true)
    await userService.updateApplicantData({ ...fields, nin: refid })
      .then((res: any) => {
        // console.log("RESPONSESSSSSSSSSSSSSSSSS", res)
        setLoader(false)
        toast.success('Application data information updated successfully', { duration: 20000, className: 'bg-success text-white' });

        setTimeout(() => {
          window.location.href = "/exam-slip/" + refid
        }, 4000)
      }, (error: { message: any; }) => {
        console.log("error messsssagegegeggegeggg", error.message)
        setLoader(false)
        toast.error(error.message, { duration: 20000, className: 'bg-danger text-white' });
      })
  })

  return (
    <div className="cbt-details my-5">
    {disable &&
    (<div className="">
      <div className="container">
            <div className="card bg-white p-2">
              <div className="table-responsive">
                <form onSubmit={onSubmit}>
                <Table bordered>
                  <tbody>
                    <tr>
                    <td colSpan={2} className="p-0">
                      <div className="card-header text-center text-dark card-status-header px-5">
                            <h2 className="text-light font-weight-bolder cbt-title text-uppercase">Check Your Status And Update Where Applicable</h2>
                      </div>
                    </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <div className="row">
                              <div className="col-md-6">
                                 <h1 className="text-success">Qualified</h1>
                                 <h1 className="text-danger font-weight-bolder">{reference_id}</h1>
                              </div>
                              <div className="col-md-6">
                              <div className="d-flex justify-content-center align-item-center py-4">
                          <div className="text-center">
                          {fields.photo
                            ? (<img src={`data:image/png;base64, ${fields.photo}`} alt="" className="w-100 cbt-profile-photo border shadow"/>)
                            : (<><img src={emptyAvarter} alt="" className="w-100 cbt-profile-photo"/>
                              <input type="file" onChange={uploadImage} name="passport" id="passport" hidden></input>
                              <br></br>
                              {fields.photo
                                ? (<button className="btn btn-success mt-2">Selected</button>)
                                : (<label htmlFor="passport" className="btn mt-2 border btn-default">Select image</label>)}
                              {uploadStatus === true && (
                                    <div className="spinner-border spinner-border-sm text-success" role="status">
                                    <span className="sr-only">Loading...</span>
                                    </div>
                              )}
                              </>)
                            }
                            </div>
                          </div>
                              </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                          <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="firstname">
                              Firstname <span className="text-danger">*</span>
                            </label>
                            <br />
                              <input
                                className="form-control"
                                type="text"
                                id="firstname"
                                 value={titleCase(firstname)}
                                disabled
                              />
                          </div>
                          <div className="form-group col-md-6">
                              <label htmlFor="surname">
                                Last Name <span className="text-danger">*</span>
                              </label>
                              <br />
                                <input
                                  className="form-control"
                                  type="text"
                                  id="surname"
                                  value={titleCase(lastname)}
                                  disabled
                                />
                            </div>

                        <div className="form-group col-md-6">
                              <label htmlFor="addressOfResidence">
                                Reference Number <span className="text-danger">*</span>
                              </label>
                              <br />
                                <input
                                  className="form-control"
                                  type="text"
                                  id="addressOfResidence"
                                  value={reference_id}
                                  disabled
                                />
                              <div className="register--error text-danger">
                                {errors.stateOfOrigin && errors.stateOfOrigin.message}
                              </div>
                            </div>

                        <div className="form-group col-md-6">
                              <label htmlFor="addressOfResidence">
                                Age <span className="text-danger">*</span>
                              </label>
                              <br />
                                <input
                                  className="form-control"
                                  type="text"
                                  id="addressOfResidence"
                                  value={dob}
                                  disabled
                                />
                            </div>

                          <div className="form-group col-md-6">
                              <label htmlFor="mobile">
                                Phone Number <span className="text-danger">*</span>
                              </label>
                              <br />
                                <input
                                  className="form-control"
                                  type="text"
                                  id="mobile"
                                  {...register("mobile", {
                                    pattern: {
                                      value:
                                      /^[0-9]*$/,
                                      message: 'Invalid input',
                                    }
                                  })}
                                  value={fields.mobile}
                                  // eslint-disable-next-line no-unneeded-ternary
                                  disabled={workflow.applicationStatus.initialized === 1 ? true : false}
                                  onChange={handleChange}
                                />
                              <div className="register--error text-danger">
                                {errors.mobile && errors.mobile.message}
                              </div>
                          </div>

                          <div className="form-group col-md-6">
                              <label htmlFor="phone">
                              Phone Number 2
                              </label>
                              <br />
                                <input
                                  className="form-control"
                                  type="text"
                                  id="phone"
                                  {...register("phone", {
                                    pattern: {
                                      value:
                                      /^[0-9]*$/,
                                      message: 'Invalid input',
                                    }
                                  })}
                                  onChange={handleChange}
                                  value={fields.phone}
                                  // eslint-disable-next-line no-unneeded-ternary
                                  disabled={workflow.applicationStatus.initialized === 1 ? true : false}
                                />
                              <div className="register--error text-danger">
                                {errors.mobile1 && errors.mobile1.message}
                              </div>
                            </div>

                        <div className="form-group col-md-6">
                              <label htmlFor="email">
                                Email Address
                              </label>
                              <br />
                                <input
                                  className="form-control"
                                  type="text"
                                  id="email"
                                  {...register("email", {
                                    pattern: {
                                      value: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                      message: 'Please enter a valid Email'
                                    }
                                  })}
                                  onChange={handleChange}
                                  // eslint-disable-next-line no-unneeded-ternary
                                  // disabled={email === null ? false : true}
                                  disabled={workflow.applicationStatus.initialized === 1}
                                  value={fields.email}
                                />
                              <div className="register--error text-danger">
                                {errors.email && errors.email.message}
                              </div>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="addressOstateOfOriginfResidence">
                                  State <span className="text-danger">*</span>
                                </label>
                                <br />
                                  <select
                                    className="form-control"
                                    id="stateOfOrigin"
                                    {...register("state")}
                                    // eslint-disable-next-line no-unneeded-ternary
                                    disabled={state === null ? false : true}
                                    onChange={ handleChange }
                                  >
                                    {states[2].data?.map((statelist: { id: any; name: any }, Index: React.Key | null | undefined) => {
                                      const { id, name } = statelist
                                      const selected = (fields.state === id);
                                      return (<option value={id} key={Index} selected={selected}>{titleCase(name)}</option>)
                                    })}
                                    </select>
                              </div>

                        <div className="form-group col-md-6">
                              <label htmlFor="lgaOfOrigin">
                                Origin LGA <span className="text-danger">*</span>
                              </label>
                              <br />
                                 <select
                                    {...register("lga")}
                                    className="form-control"
                                    id="lgaOfOrigin"
                                    onChange={handleChange}
                                    // eslint-disable-next-line no-unneeded-ternary
                                    disabled={lga === null ? false : true}
                                  >
                                   <option>Select your LGA</option>
                                    {selectedLga?.map((lgalist: any, Index: React.Key | null | undefined) => {
                                      const { id, name } = lgalist
                                      return (<option value={id} key={Index}>{titleCase(name)}</option>)
                                    })}
                                    </select>
                            </div>
                              <div className="form-group col-md-12 pt-4">

                              <input type="checkbox" id="acknow" name="ack" value="yes" required onChange={() => setSubmitBtn(!submitBtn)}/>
                              <label htmlFor="acknow"> &nbsp; I certify that the above information on this application is true and correct to the best of my knowlege</label><br/>
                               {workflow.applicationStatus.initialized === 0
                                 ? (<button className="btn btn--default btn-block w-50" disabled={submitBtn}>Update and Print Slip</button>)
                                 : (<div className='text-danger'>Profile already updated</div>)}
                              </div>
                          </div>
                      </td>
                    </tr>
                    {workflow.aptitude.status !== 'qualified'
                      ? (<>
                    </>)
                      : (<></>)}
                  </tbody>
                </Table>
              </form>
              </div>
            </div>
      </div>
    </div>
    )}

<Loader show={loader}/>
          <Toaster/>
</div>
  )
}

export default RegistrationStatusPass
