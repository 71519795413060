import React, { FC, useEffect, useState } from 'react';
import './MedicalDetails.scss';
// eslint-disable-next-line no-unused-vars
import emptyAvarter from './../../../../../Assets/images/user-empty-avatar.png';
import Table from 'react-bootstrap/esm/Table';
import psc from '../../../../../Assets/images/psc_logo.png';
import npfl from '../../../../../Assets/images/logo.png';
import { useSelector } from 'react-redux';

interface propsType {
  value: any;
  disable: boolean;
}

const MedicalDetails: FC<propsType> = ({ value, disable }) => {
  const [hideButton, setHiddenButton] = useState<boolean>(false);
  const [admissionInfo, setAdmissionInfo] = useState<string | any>();
  const { success } = useSelector((state: { user: any }) => state.user);
  const { dataset, photo, authQR } = success;
  const [resData, setResData] = useState<any>()
  useEffect(() => {
    const commandss = localStorage.getItem('MENU_COMMAND')
    if (commandss !== null) {
      // const { datas } = JSON.parse(commandss)
      const { data } = JSON.parse(commandss)
      //   console.log('HI', data)
      setResData(data)
    }
    const dateEx: string | null = localStorage.getItem('MENU_COMMAND');
    const dataJson = dateEx !== null && JSON.parse(dateEx);
    const dateJson = dataJson.data.admissionYear;
    setAdmissionInfo(dateJson);
  }, []);
  // console.log("VAL VAL", value)
  return (
    <>
      {
        <div className='pSchedule'>
          <div className='cbt-details my-5'>
            <div className=''>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-8 offset-md-2'>
                    <div className='card bg-white p-2'>
                      <div className='table-responsive overflow-hidden'>
                        <Table bordered className='rounded-5'>
                          <tbody>
                            <tr>
                              <td colSpan={2} className='p-0'>
                                <div className='card-header text-center text-dark card-status-header px-5'>
                                  <div className='row'>
                                    <div className='col'>
                                      <div className='row'>
                                        <div className='col d-flex justify-content-end'>
                                          <img
                                            className='img-responsive rounded-circle'
                                            width='100'
                                            height='100'
                                            src={psc}
                                          />
                                        </div>
                                        <div className='col d-flex justify-content-start'>
                                          <img
                                            className='img-responsive'
                                            width='100'
                                            height='100'
                                            src={npfl}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='mb-0 pb-0 pt-4'>
                                    <h1 className='text-light font-weight-bolder cbt-title fs-1 p-0'>
                                      {' '}
                                      {admissionInfo || '2022'} Nigeria Police
                                      Force Recruitment
                                    </h1>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td colSpan={2}>
                                <div className='mt-0 pt-0'>
                                  <h5 className='text-dark text-center pt-2 '>
                                    Medical Screening Invitation Slip
                                  </h5>
                                </div>
                                <div className='d-flex justify-content-center py-4 row'>
                                  <div className='col justify-content-end d-flex'>
                                    <img
                                      className='img-responsive'
                                      width='120'
                                      height='120'
                                      src={`data:image/png;base64, ${
                                        photo !== undefined ? photo : ''
                                      }`}
                                    />
                                  </div>
                                  <div className='col align-self-center'>
                                    <div className='text-secondary font-weight-bolder fs-5 text-start text-name'>
                                      NAME: {dataset?.formData?.surname || ''}{' '}
                                      {dataset?.formData?.firstname}{' '}
                                      {dataset?.formData?.middlename}
                                    </div>
                                    <div className='text-secondary font-weight-bolder fs-5 text-start'>
                                      NIN: {dataset?.formData.nin}
                                    </div>
                                    <div className='text-secondary fw-bolder'></div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr className='cand-info-bg'>
                              {dataset?.workflow?.metadata?.initialized !==
                              0
                                ? (
                                <td colSpan={2}>
                                  {dataset?.workflow?.medical
                                    ?.scheduleSchema
                                    ? (
                                    <>
                                      <div className='pt-3 '>
                                        <div className='text-center'>
                                          <b>DATE:</b>
                                        </div>
                                        <div className='text-center info-text'>
                                          {dataset?.workflow?.medical
                                            ?.scheduleSchema['medical-date'] ||
                                            'Not Avaliable'}
                                        </div>
                                      </div>
                                      <div className='pt-3 '>
                                        <div className='text-center'>
                                          <b>TIME:</b>
                                        </div>
                                        <div className='text-center info-text'>
                                          {dataset?.workflow?.medical
                                            ?.scheduleSchema['medical-time'] ||
                                            'Not Avaliable'}
                                        </div>
                                      </div>
                                      <div className='pt-3 '>
                                        <div className='text-center'>
                                          <b>VENUE:</b>
                                        </div>
                                        <div className='text-center info-text'>
                                          {dataset?.workflow?.medical
                                            ?.scheduleSchema['medical-venue'] ||
                                            'Not Avaliable'}
                                        </div>
                                      </div>
                                    </>
                                      )
                                    : (
                                    <>
                                      <h5 className='text-center '>
                                        Medical Scheduled Credential not
                                        Available
                                      </h5>
                                    </>
                                      )}{' '}
                                </td>
                                  )
                                : (
                                <td colSpan={2}>
                                  <h5 className='text-danger text-center'>
                                    Disqualified
                                  </h5>
                                </td>
                                  )}
                            </tr>
                            <tr>
                              <td>
                                {dataset?.workflow?.medical.scheduleSchema && (
                                  <div className=' mt-5'>
                                    <div className='text-center col'>
                                      <img
                                        className='img-responsive'
                                        src={`data:image/png;base64, ${authQR}`}
                                        width={200}
                                        height={200}
                                        alt='qrcode'
                                      />
                                      <div className='text-center'>
                                       BATCH {
                                          resData?.mergedAdmissionSettings
                                            .currentBatchPhase
                                        }
                                      </div>
                                    </div>
                                    <div className='text-start col mt-4 d-flex align-self-right'>
                                      {' '}
                                      <div className='text-center text-black cpb-note'>
                                        <div>
                                          {' '}
                                          NOTE: You are to attend the exercise as scheduled above with the following :
                                        </div>
                                        <li>This Letter of Invitation</li>
                                        <li>Form Submission Slip</li>
                                        <li>NIN slip</li>
                                        <div>
                                          {' '}
                                          <div className='text-center text-black cpb-note'> Dress code: White T-Shirt and short </div>
                                          <div className='text-black'> NB: No Candidate will be attended to without
                                            full compliance with the above. </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td className='text-center close-col' colSpan={2}>
                                <div className='pcolor1 px-5'>
                                  <div className='section-color-tage1'></div>
                                  <div className='section-color-tage2'></div>
                                  <div className='section-color-tage3'></div>
                                  <div className='section-color-tage4'></div>
                                  <div className='section-color-tage5'></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='pt-3 pb-5 justify-content-center d-flex'>
                <button
                  className={
                    hideButton ? 'd-none' : 'btn w-25 btn--default btn-block'
                  }
                  onClick={() => {
                    setHiddenButton(true);
                  }}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default MedicalDetails;
