
import Button from 'react-bootstrap/esm/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import "./ConfirmationAlert.scss"

function ConfirmationAlert (action: (() => void), title: string, message:string, buttonText:string) {
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
          <div className='confirm-ui'
          data-aos="zoom-in-down"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          >
            <div className="card">
              <div className="card-body text-center">
                <div className='text-center mt-2 mt-md-5'>
                  <h1>{title}</h1>
                  <div className="text-secondary"> {message} </div>
                  <div className='bg-light  icon-container rounded-circle shadow-md my-2'><i className={`fa fa-info fa-4x text-danger`}></i></div>
                </div>
              </div>
              <div className="card-footer bg-white pb-5 border-0">
              <div className="row">
                <div className="col-12 text-center">
                   <Button variant="primary" size="lg" onClick={() => {
                     action()
                     onClose();
                   }}> {buttonText}  </Button>
                </div>
               </div>
              </div>
            </div>
          </div>
      );
    },
    overlayClassName: "overlay-custom-class-name"
  });
}

export default ConfirmationAlert;
