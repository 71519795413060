import { Fragment, useState, useCallback, useEffect, FormEvent, SetStateAction } from 'react';
import axios from 'axios';
import Footer from '../../../../Layout/Footer';
import { RegisterHeader } from '../../Components/Index';
import { BASE_URL_ADDR } from '../../../../Constants/BaseURL';
import { useParams } from 'react-router';
import "./PrintSlip.scss"
const PrintSlip = () => {
  const { nin }: any = useParams<any>();
  const [refInput, setRefInput] = useState<string>(nin || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationResults, setApplicationResults] = useState<any>();
  const [applicationPayment, setApplicationPayment] = useState<any>();
  const [qRcode, setQRCode] = useState('')
  const [error, setError] = useState<any>();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    preview();
  }

  const preview = async () => {
    setLoading(true);

    axios({
      method: 'GET',
      url: `${BASE_URL_ADDR}/recruitment/print-slip?nin=${refInput}`,
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(async response => {
      const data = response.data;
      console.log("data", data);
      setQRCode(data.authQR)
      setApplicationResults({ photo: data.photo, ...data.dataset.formData, ...data.dataset.nimc });
      setApplicationPayment(data.metadata);
    }).catch(error => {
      // console.log("data", error);
      setError(error?.response?.data?.message || "Application does no exist!");
    }).finally(() => {
      setLoading(false);
    })
  }

  const changeRef = useCallback((e: { target: { value: SetStateAction<string>; }; }): void => {
    setRefInput(e.target.value);
  }, [setRefInput]);

  useEffect(() => {
    if (nin) {
      preview();
    }
    return () => { }
  }, [nin])
  // console.log(applicationResults)

  const year = new Date(Date.now()).getFullYear();
  // console.log(year);

  const Exam_Type: any = (applictionResult: any) => {
    if (applicationResults && applicationResults?.waec === true) {
      return "WAEC"
    }
    if (applicationResults && applicationResults?.neco === true) {
      return "NECO"
    }
    if (applicationResults && applicationResults?.nabteb === true) {
      return "NABTEB"
    }
    if (applicationResults && applicationResults?.gce === true) {
      return "GCE"
    }
  }
  // console.log("QQQQ", qRcode);
  return (
    <Fragment>
      <RegisterHeader />
      <main role="main" className="container print--slip">
        {
          (!applicationResults) &&
          <section className="print--slip-form container">
            <div className="card border-0 rounded my-5">
              <div className="card-header section-header-bg">
                Print Your Confirmation Slip Here
              </div>
              <div className="card-body p-4 row">
                <div className="col-md-6 offset-md-3  w-5 text-center">
                  <form onSubmit={onSubmit}>
                    {
                      !nin &&
                      <div className="form-group">
                        <label htmlFor="refid">Enter your NIN</label>
                        <input onChange={changeRef} value={refInput} type="text" id="refid" className="form-control refinput" disabled={loading} />
                        <p className="error-value" style={{ color: 'red' }}>{error}</p>
                      </div>
                    }
                    <button disabled={loading} className="btn btn-warning text-dark shadow btn-block" type="submit" >Preview {loading && <div style={{ marginTop: 10 }} className="spinner-border spinner-border-sm"></div>}</button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        }

        {
          (applicationResults) &&
          <section className="my-5">
            <div className="container">
              <div className="pb-5 mt-4 mb-3 bg-white shadow">
                <div className=" bg-primary rounded-top">
                  <div className=" w-100 float-left p-4 border-0 section-header-bg">
                    <div className="row h-100">
                      <div className="col-sm-3 my-auto text-center">
                        <div className='row'>
                          <div className="col">
                            <img className="img-responsive rounded-circle" width='120' height='120' src='psc_logo.png' />
                          </div>
                          <div className="col">
                            <img className="img-responsive" width='120' height='120' src='police_logo.png' />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 my-auto text-center">
                        <h2 className='text-light'> 2022 Nigeria Police Force Recruitment Application Confirmation Slip</h2>
                        <h1 className='text-light fw-bold'>{applicationResults?.surname?.slice(0, 1).toUpperCase() + applicationResults?.surname?.slice(1).toLowerCase() || ''} {applicationResults?.middlename?.slice(0, 1).toUpperCase() + applicationResults?.middlename?.slice(1).toLowerCase() || ''}  {applicationResults?.firstname?.slice(0, 1).toUpperCase() + applicationResults?.firstname?.slice(1).toLowerCase() || ''}</h1>
                        <h5 className='text-light fw-bold'>NIN: {applicationResults?.nin}</h5>
                        {applicationResults?.referenceNo && <h5 className='text-light fw-bold'>Reference Number: {applicationResults?.referenceNo}</h5>}
                      </div>

                      <div className="col-sm-3 my-auto text-center">
                        <img className="img-responsive" width='120' height='120' src={`data:image/png;base64, ${applicationResults.photo || ''}`} />
                      </div>
                    </div>
                  </div>
                </div>

                {applicationPayment?.isQualified ||
                <div className='container-fluid'>
                  <div className="row justify-content-center">
                    <div className="col-sm-6">
                      <div className='alert alert-danger text-center'>
                        <h2 className='fw-bolder'>FAILED RECRUITMENT APPLICATION</h2>
                        <div>{applicationResults?.pipelineStatusMessage}</div>
                        <div> {applicationPayment?.pipelineStatusMessage.map((message: any, index: number) => (
                          <div key={index} dangerouslySetInnerHTML={{ __html: message }} />
                        )

                        )
                        }</div>
                      </div>
                    </div>
                  </div>
                </div>}
                {applicationPayment && <div>
                  <div className='container'>
                    <table className='col-12 text-center'>
                      <tbody>
                        <tr>
                          <th className="border p-1">Application Status</th>
                          <th className="border p-1">Candidate Role</th>
                        </tr>
                        <tr>
                        <th className="border p-1">{applicationPayment?.isQualified ? <span className='text-success'>Qualified</span> : <span className='text-danger'>Disqualified</span>}</th>
                          <th className="border p-1">{applicationResults?.candidateRole === 'generalDuty' ? 'General Duty' : <>Specialist ({applicationResults?.skillTitle})</>}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>}

                  <div className='container-fluid'>
                    <div className='table-responsive'>
                      <table className="table float-left w-100 h-5">
                        <tbody>
                          <tr>
                            <td>
                              <div>
                                <p style={{ fontSize: 18 }}><strong>Personal Information:</strong></p>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <table className="text-center border-dark w-100" style={{ borderCollapse: 'collapse' }}>
                                  <tbody>
                                    <tr>
                                      <th className="border p-1">Name</th>
                                      <th className="border p-1">Gender</th>
                                      <th className="border p-1">DOB</th>
                                      <th className="border p-1">Marital status</th>
                                    </tr>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.surname + ' ' + applicationResults?.firstname || ''}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.gender || ''}</td>
                                      <td className="border p-1" id="dob_view">{applicationResults?.birthdate || ''}</td>
                                      <td className="border p-1" id="marital_status_view">{applicationResults?.maritalstatus || ''}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div>
                                <table className="text-center border-dark w-100" style={{ borderCollapse: 'collapse' }}>
                                  <thead>
                                    <tr>
                                      <th className="border p-1">Email</th>
                                      <th className="border p-1">Phone 1</th>
                                      <th className="border p-1">Phone 2</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="border p-1" id="email_view">{applicationResults?.email || ''}</td>
                                      <td className="border p-1" id="phone_1_view">{applicationResults?.phone || ''}</td>
                                      <td className="border p-1" id="phone_2_view">{applicationResults?.contactPhone || ''}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                {applicationPayment?.isQualified && <>
                  <br />
                  <div className='container-fluid'>
                    <div className='table-responsive'>
                      <table className="table float-right border p-1 w-100">
                        <tbody>
                          <tr>
                            <td className="pl-1">
                              <p style={{ fontSize: 18 }}><strong>Next of Kin Information</strong></p>
                              <div style={{ textAlign: 'center' }}>
                                <table className="text-center border-dark w-100" style={{ borderCollapse: 'collapse' }}>
                                  <thead>
                                    <tr>
                                      <th className="border p-1">Name</th>
                                      <th className="border p-1">Phone</th>
                                      <th className="border p-1">Email</th>
                                      <th className="border p-1">Relationship</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.nok_firstname || ''}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.nok_surname || ''}</td>
                                      <td className="border p-1" id="dob_view">{applicationResults?.nextOfKinEmail || ''}</td>
                                      <td className="border p-1" id="marital_status_view">{applicationResults?.nextOfKinRelationship || ''}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <br />

                  <div className='container-fluid mb-4'>
                    <table className="float-left p-1 w-100 mt-1 border">
                      <tbody>
                        <tr>
                          <td className="pl-2">
                            <p style={{ fontSize: 18 }}><strong> Permanent Address</strong></p>
                            <p><span id="permanent_state_view"><strong>State:</strong> {applicationResults?.stateOfPermanent}</span> </p>
                            <p><span id="permanent_lga_view"><strong>LGA:</strong> {applicationResults?.lgaOfPermanent}</span></p>
                            <p><span id="permanent_street_view"><strong>Street:</strong> {applicationResults?.streetAddressOfPermanent}</span></p>
                          </td>

                          <td>
                            <p style={{ fontSize: 18 }}><strong>Resident Address</strong></p>
                            <p><span id="resident_state_view"><strong>State:</strong> {applicationResults?.stateOfResidence}</span> </p>
                            <p><span id="resident_lga_view"><strong>LGA:</strong> {applicationResults?.lgaOfResidence}</span></p>
                            <p><span id="resident_street_view"><strong>Street: </strong>{applicationResults?.streetAddressOfResidence}</span></p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='container-fluid'>
                    <div className='table-responsive'>
                      <table className="table float-right border p-1 w-100">
                        <tbody>
                          <tr>
                            <td className="pl-1">
                              <div className={'row row-cols-2'}>
                                <div className={'col'}><p style={{ fontSize: 18 }}>Exam Type: <strong>{Exam_Type()}</strong></p></div>
                                <div className={'col  me-auto'}><p style={{ fontSize: 18 }}>Exam Number: <strong>{applicationResults.firstSittingRegNo}</strong></p></div>
                              </div>

                              <div style={{ textAlign: 'center' }}>
                                <table className="text-center border-dark w-100" style={{ borderCollapse: 'collapse' }}>
                                  <thead>
                                    <tr>
                                      <th className="border p-1">Subject</th>
                                      <th className="border p-1">Grade</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.firstSitting?.subject1 || "MATHEMATICS"}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.firstSitting?.grade1 || "-"}</td>

                                    </tr>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.firstSitting?.subject2 || "ENGLISH"}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.firstSitting?.grade2 || "-"}</td>

                                    </tr>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.firstSitting?.subject3 || "Unknown Subject"}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.firstSitting?.grade3 || "-"}</td>

                                    </tr>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.firstSitting?.subject4 || "Unknown Subject"}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.firstSitting?.grade4 || "-"}</td>

                                    </tr>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.firstSitting?.subject5 || "Unknown Subject"}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.firstSitting?.grade5 || "-"}</td>

                                    </tr>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.firstSitting?.subject6 || "Unknown Subject"}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.firstSitting?.grade6 || "-"}</td>

                                    </tr>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.firstSitting?.subject7 || "Unknown Subject"}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.firstSitting?.grade7 || "-"}</td>

                                    </tr>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.firstSitting?.subject8 || "Unknown Subject"}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.firstSitting?.grade8 || "-"}</td>

                                    </tr>
                                    <tr>
                                      <td className="border p-1" id="name_view">{applicationResults?.firstSitting?.subject9 || "Unknown Subject"}</td>
                                      <td className="border p-1" id="gender_view">{applicationResults?.firstSitting?.grade9 || "-"}</td>
                                    </tr>

                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center'><img className="img-responsive" src={`data:image/png;base64, ${qRcode}`} width={200} height={200} alt='qrcode' /></div>
                  <div className="text-center h-4 mt-2 pt-3">
                    <div>Please come along with a copy of this form during the screening process and also a copy of your guarantor form</div>
                    <div>Nigeria Police Force Recruitment {year}</div>
                  </div>
                </>}
                <div className="text-center mt-5">
                  <button className="btn w-25 btn-primary" onClick={() => window.print()} >Print</button>
                </div>
              </div>
            </div>
          </section>
        }
      </main>
      <Footer />
    </Fragment>
  );
}
export default PrintSlip;
